import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index';

import jQuery from 'jquery'

import 'ant-design-vue/dist/antd.css';


import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'

import customFileList from '@/components/custom-file-list';

import Vuex from 'vuex';

import {dispatchEventStroage} from '@/utils/watchLocalStorage.ts'

import tool from './utils/tool'


createApp(App)
    .component('custom-file-list', customFileList)
    .use(router)
    .use(jQuery)
    .use(store)
    .use(ElementPlus)
    .use(Vuex)
    .use(tool)
    .use(dispatchEventStroage)
    .mount('#app')

