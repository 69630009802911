import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/index',
  },

  {
    path: '/index',
    name: 'index',
    component: () => import('@/views/index/index.vue')
  },

  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login.vue')
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('@/views/products/products.vue')
  },
  {
    path: '/details',
    name: 'details',
    component: () => import('@/views/product-details/product-details.vue')
  },
  {
    path: '/gallery-template',
    name: 'gallery-template',
    component: () => import('@/views/gallery-template/gallery-template.vue')
  },
  {
    path: '/gallery-details',
    name: 'gallery-details',
    component: () => import('@/views/gallery-template/gallery-details.vue')
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('@/views/cart/cart.vue')
  },
  {
    path: '/cartAddress',
    name: 'cartAddress',
    meta:{
      keepAlive:true
    },
    component: () => import('@/views/cart/cart-address/cart-address.vue')
  },
  {
    path: '/pay',
    name: 'pay',
    component: () => import('@/views/pay/pay.vue')
  },

  {
    path: '/my',
    name: 'my',
    component: () => import('@/views/my/myInfo.vue')
  },
  {
    path: '/myorder-details',
    name: 'myOrderDetails',
    component: () => import('@/views/my/order-details.vue')
  },
  {
    path: '/myAddress',
    name: 'myAddress',
    component: () => import('@/views/my/myAddress.vue')
  },
  {
    path: '/my-collect',
    name: 'myCollect',
    component: () => import('@/views/my-collect/my-collect.vue')
  },
  {
    path: '/graphic-class',
    name: 'graphicClass',
    component: () => import('@/views/graphic-class/graphic-class.vue')
  },
  {
    path: '/graphic-detail',
    name: 'graphicDetail',
    component: () => import('@/views/graphic-class/graphic-detail.vue')
  },
  {
    path: '/recommended',
    name: 'recommended',
    component: () => import('@/views/recommended/recommended.vue')
  },
  {
    path: '/demand',
    name: 'demand',
    component: () => import('@/views/demand/demand.vue')
  },
  {
    path: '/my-demand',
    name: 'my-demand',
    component: () => import('@/views/my/my-demand.vue')
  },
  {
    path: '/stored-value',
    name: 'stored-value',
    component: () => import('@/views/stored-value/stored-value.vue')
  },
  {
    path: '/coupon',
    name: 'coupon',
    component: () => import('@/views/coupon/coupon.vue')
  },
  {
    path: '/figure-powder-member',
    name: 'figure-powder-member',
    component: () => import('@/views/figure-powder-member/figure-powder-member.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/introduce/about.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/introduce/privacy.vue')
  },
  {
    path: '/question',
    name: 'question',
    component: () => import('@/views/introduce/question.vue')
  },
  {
    path: '/distribution',
    name: 'distribution',
    component: () => import('@/views/introduce/distribution.vue')
  },
  {
    path: '/cooperation',
    name: 'cooperation',
    component: () => import('@/views/introduce/cooperation.vue')
  },
  {
    path: '/integral-mall',
    name: 'integral-mall',
    component: () => import('@/views/introduce/integral-mall.vue')
  },
  {
    path: '/invoice',
    name: 'invoice',
    component: () => import('@/views/invoice/invoice.vue')
  }
  

  
]


const router = createRouter({
  history: createWebHistory(),
  routes,
})


export default router
