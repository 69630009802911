<template>
  <div>

    <div class="preloader" v-if="isLoading">
		  <div class="content">
			  <div class="box"></div>
		  </div>
	  </div>

    <header class="header-area" style="background: #f8f8f9;"><!-- border-bottom: solid 1px #007dc9; -->
      <!-- 最顶部灰色条栏 -->
      <div class="top-header" style="padding:0;">
        <div class="container" style="height:38px;">
          <div class="row align-items-center">

            <div class="col-lg-8" style="text-align: left" v-if="isMobile">
              <ul class="header-left-content">
                <li class="to-margin-top"><a  @click="login"> {{isLoginName}} </a> </li>
                <li v-if="isLogin" style="position: relative;height: 0;">
                  <div>
                    <img src="@/assets/static/index/hylogo.png" style="width:90px;"/> 
                    <span class="hy-name">{{ gname }}</span>
                  </div>
                </li>
              </ul>
             
            </div>

            <div class="col-lg-4">
              <div class="header-right-content" v-if="isMobile">
                <ul>
                  <li class="to-margin-top">
                  <a href="#" @click="checkRoom"> {{ ridName }} </a>
                  <div class="room-body" v-if="ischeckRoom">
                    <div class="row">
                      <div class="col" v-for="(item, index) in roomList" :key="index" style="border-right: solid 1px #cdcdcd" >
                        <h6>{{ item.name }}</h6>
                        <div v-for="(info, num) in item.list" :key="num" style="padding: 7px" >
                          <a style="color:#000000"  @click="selectRoom(info)">{{ info.name }}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="to-margin-top"><a @click="orderTracking"> 订单跟踪 </a></li>
                <!-- <li> 客服 <a href="tel:+1-(514)-321-4567"> <span> 联系客服 </span> </a> </li> -->
                <li class="to-margin-top">
                 <a href="https://work.weixin.qq.com/kfid/kfc7f17646550b3ddf0"> <span> 联系客服 </span> </a>
                </li>
                <li class="to-margin-top"><a @click="about"> 关于我们 </a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 下一个白色带商城图标栏 -->
      <div class="middle-header">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="logo">
                <a > <img src="@/assets/static/index/header_title.png" alt="Image" @click="goIndex" />  </a>
              </div>
            </div>
            <div class="col-lg-5">

              <div class="search-box" style="display: flex;align-items: center;border: solid 1px #007dc9;border-radius: 5px;">
                <el-popover placement="bottom" :width="50" trigger="click" :visible="searchVisible">
                  <template #reference>
                    <div class="prop-left-change" @click="searchVisible = !searchVisible" :class="isMobile == false ? 'isMobile-width': ''">
                      {{search_name}}
                      <span v-if="searchVisible == false"><img class="search-img-icon" src="@/assets/static/icons/jianbottom.png" /></span>
                      <span v-if="searchVisible == true"><img class="search-img-icon" src="@/assets/static/icons/jiantop.png" /></span>
                    </div>
                    
                  </template>
                  <div class="text-center">
                    <div class="p-sm-2 border-bottom" @click="changePropver(1,'商品')" style="cursor: pointer;" :class="isMobile == false ? 'isMobile-padding':''">商品</div>
                    <div class="p-sm-2 border-bottom" @click="changePropver(2,'图文')" style="cursor: pointer;" :class="isMobile == false ? 'isMobile-padding':''">图文</div>
                    <div class="p-sm-2" @click="changePropver(3,'模板')" style="cursor: pointer;" :class="isMobile == false ? 'isMobile-padding':''">模板</div>
                  </div>
                </el-popover>
                  
                  <input v-model="searchInput" placeholder="搜索" class="form-control" style="border:0;padding:0 10px;width:68%;height: 35px;">
                  <button type="submit" class="new-search-btn" @click="searchClick" style="width:20%;padding:10px 20px;" :style="isMobile == false ? 'width:28%;': ''" > <i class="ri-search-line"></i> 搜索 </button>
              </div>
              <div class="sea-span-body">
                <span class="search-bottom-text sea-text-red" @click="spanClick('铜版纸名片')">铜版纸名片</span> 
                <span class="search-bottom-text sea-text-red" @click="spanClick('门型展架')">门型展架</span> 
                <span class="search-bottom-text sea-text-red" @click="spanClick('宣传单')">宣传单</span> 
                <span class="search-bottom-text" @click="spanClick('不干胶')">不干胶</span> 
                <span class="search-bottom-text" @click="spanClick('标书代写')">标书</span> 
                <span class="search-bottom-text" @click="spanClick('文化墙')">文化墙</span>
              </div>

            </div>
            <div class="col-lg-4" v-if="isMobile">
              <ul class="wish-cart">
                <li>
                  <div style="position: absolute;top:-24px;left: -120px;">
                    <el-popover placement="right" :width="400" trigger="click" :hide-after="0">
                      <template #reference>
                        <img src="@/assets/static/codeImg/qrCodeHeader.png" style="width:100px"/>
                      </template>
                      <div style="text-align: center;">
                        <h5>微信扫一扫</h5>
                        <img src="@/assets/static/codeImg/qrCodeHeader.png" style="width:400px"/>
                      </div>
                    </el-popover>
                    
                  </div>
                      
                </li>
                <li @click="gotoShop">
                  <span class="cart" data-bs-toggle="modal" data-bs-target="#exampleModal-cart" >
                    <span class="wish-icon"> <i class="ri-shopping-cart-line"></i> </span>
                    <span class="favorite">购物车</span>我的购物车
                  </span>
                </li>
                
              </ul>
            </div>
          </div>
        </div>
      </div>


      <!-- 购物车展示页 -->
      <div class="modal fade cart-shit" id="exampleModal-cart" tabindex="-1" aria-hidden="true" >
        <div class="cart-shit-wrap">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close-btn" data-bs-dismiss="modal">
                  <i class="ri-close-fill"></i>
                </button>
              </div>
              <div class="modal-body" v-if="shopList">
                <ul class="cart-list">
                  <li v-for="(item,index) in shopList" :key="index" style="padding-bottom:20px;">
                    <img :src="item.main_url" alt="Image" />
                    <a style="width:300px;"> {{item.name}} </a>
                    <span>￥{{item.totalPrice}}</span>
                    <i class="ri-close-fill" @click="delGood(index)"></i>
                  </li>
                </ul>
                <ul class="payable">
                  <li>共计</li>
                  <li class="total"> <span>￥{{shopMoney}}</span> </li>
                </ul>
                <ul class="cart-check-btn">
                  <li @click="gotoCart"> <a class="default-btn"  data-bs-dismiss="modal"> 查看购物车 </a> </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="navbar-area navbar-area-style-two">
        <div v-if="isMobile == false">
          <div style="display: flex;justify-content: space-between; padding: 0 12px; font-weight: bold;">
            <div @click="getApplet">首页</div>
            <div @click="getApplet">商品分类</div>
            <div @click="getApplet">浏览</div>
            <div @click="getApplet" style="color: #007dc9;"> <div class="xq-icon">HOT</div> 我要发布需求 </div>
          </div>
        </div>
        <!-- 页面首行 -->
        <div class="desktop-nav">
          <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
              <div class="navbar-category">
                <button type="button" id="categoryButton" data-bs-toggle="dropdown" aria-expanded="false" aria-haspopup="true" >
                  <i class="ri-menu-line"></i>
                  所有类别
                  <i class="arrow-down ri-arrow-down-s-line"></i>
                </button>
                <div class="navbar-category-dropdown dropdown-menu" aria-labelledby="categoryButton" style="width:298px;max-height: 492px;">
                  <el-scrollbar height="490px" v-if="isIndex" :always="true">
                    <ul v-for="(item, index) in leftList" :key="index">
                      <li @click="goProducts(item.id)">
                        <a href="#">{{ item.name }}</a>
                      </li>
                    </ul>
                  </el-scrollbar>
                </div>
              </div>
              <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent" >
                <ul class="navbar-nav">
                  <li class="nav-item float-end"> <a @click="goIndex" class="nav-link"> 首页 </a> </li>
                  <li class="nav-item mega-menu">
                    <a  class="nav-link" v-on:mouseover="clickspfl = true"> 商品分类 <i class="ri-arrow-down-s-line"></i> </a>
                    <ul class="dropdown-menu" v-if="clickspfl">
                      <li class="nav-item">
                        <div class="row">
                          <div class="col" v-for="(item, index) in treeList" :key="index" >
                            <h6 class="sub-menu-title">{{ item.name }}</h6>
                            <ul class="sub-menu">
                              <li v-for="(info, num) in item.children" :key="num" @click="goProducts(info.id)" >
                                <a>{{ info.name }}</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li class="nav-item">
                    <a  class="nav-link"> 浏览 <i class="ri-arrow-down-s-line"></i> </a>
                    <ul class="dropdown-menu">
                      <li class="nav-item"> <a @click="about" class="nav-link">关于</a> </li>
                      <li class="nav-item"> <a @click="getQuestion" class="nav-link">常见问题</a> </li>
                      <li class="nav-item"> <a @click="getPrivacy" class="nav-link">隐私政策</a> </li>
                    </ul>
                  </li>
                  
                  <li class="nav-item"> <a @click="goMyCoupon" class="nav-link">领券中心</a> </li>
                  <li class="nav-item"> 
                    <div class="xq-head-hot" >HOT</div> <a @click="demand" class="nav-link" style="color:#007dc9;">我要发布需求</a> 
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>

    <div v-if="topLogin">
      <div :class="topLogin == true ? 'show-login' : 'hide-login'"  @click="hidelogin" >
        <div class="login-body">
          <div>
            <h5>登录</h5>
            <div>请使用微信扫下方二维码</div>
            <wxlogin
              :appid="appid"
              :scope="scope"
              :redirect_uri="redirect_uri"
              href="data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsKCiBkaXNwbGF5OiBub25lOwoKfQoKLmltcG93ZXJCb3ggLnN0YXR1cy5zdGF0dXNfYnJvd3NlciB7CgogZGlzcGxheTogbm9uZTsKCn0KCi5pbXBvd2VyQm94IC5xcmNvZGUgewoKIGJvcmRlcjogbm9uZTsKCiB3aWR0aDogMjgwcHg7CgogaGVpZ2h0OiAyODBweDsKCn0KCi5pbXBvd2VyQm94IC5zdGF0dXN7CgogZGlzcGxheTogbm9uZQoKfQ=="
            ></wxlogin>
          </div>
          <div></div>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
import "@/assets/static/js/jquery.min.js";
import "@/assets/static/js/bootstrap.bundle.min.js";
import "@/assets/static/js/meanmenu.min.js";

import "@/assets/static/js/wow.min.js";
import "@/assets/static/js/range-slider.min.js";
import "@/assets/static/js/form-validator.min.js";
import "@/assets/static/js/contact-form-script.js";
import "@/assets/static/js/ajaxchimp.min.js";

import "@/assets/static/js/owl.carousel.min.js";
import "@/assets/static/js/custom.js";

import "@/assets/static/css/bootstrap.min.css";
import "@/assets/static/css/owl.theme.default.min.css";
import "@/assets/static/css/owl.carousel.min.css";
import "@/assets/static/css/remixicon.css";
import "@/assets/static/css/meanmenu.min.css";
import "@/assets/static/css/animate.min.css";
import "@/assets/static/css/style.css";
import "@/assets/static/css/responsive.css";

import wxlogin from "vue-wxlogin";
import * as Api from "@/views/index/api";
import * as util from '@/utils/loginUtil'
import tools from '@/lib/common'
import { ElMessage} from 'element-plus'

import {isWindow,goApplet} from '@/views/index/goApplet'

export default {
  components: {
    wxlogin,
  },
  computed:{
      getCheckLogin(){
        return this.$store.state.checkLogin
      }
    },
  watch: {

    // 登录状态检测
    getCheckLogin:{
      handler(newValue,oldValue){
        this.isLogin = newValue
        if(this.isLogin){
          let custom = JSON.parse( localStorage.getItem('custom') )
          let name = custom.name?custom.name:custom.nickName
          this.isLoginName = 'Hi欢迎您，[ '+name+' ]'
          this.gname = custom.gname
        } else{this.isLoginName = 'Hi欢迎您，请[登录]';this.gname = '1231321'}
      }
    },

    // 路由监听
    $route: {
      handler: function(val, oldVal){
        if(val.name === 'index'){ 
          this.isIndex = false
          this.search_index = 1
          this.search_name = '商品'
        }else{ this.isIndex = true }
        if(val.name === 'graphicClass'){
          this.search_index = 2
          this.search_name = '图文'
        }
        if(val.name === 'gallery-template'){
          this.search_index = 3
          this.search_name = '模板'
        }
      },
      // 深度观察监听
      deep: true
    },

  },
  mounted() {
    this.getStoreList();
    this.isMobile = isWindow()
    let check = this.$store.state.checkLogin
    let hc = localStorage.getItem('is_login') === 'true'
    if(hc){ check = hc; this.$store.dispatch('setCheckLogin',true) }
    this.isLogin = check
    if(this.isLogin){
      let custom = JSON.parse( localStorage.getItem('custom') )
      let name = custom.name?custom.name:custom.nickName
      this.isLoginName = 'Hi欢迎您，[ '+name+' ]'
      this.gname = custom.gname
    } else{this.isLoginName = 'Hi欢迎您，请[登录]';this.gname = '123456'}

  },
  data() {
    return {
      qrCodeImg:'@/assets/static/codeImg/qrCodeHeader.png',
      isMobile:false,

      isIndex:false,


      isLoading:true,
      isLoginName:'Hi欢迎您，请[登录]',
      gname:'123456',

      treeList: [],
      leftList: [],

      shopList:[],
      shopMoney:0,

      topLogin: false,

      rid: "",
      ridName: "",
      ischeckRoom: "",

      isLogin: false,

      startMobile: false,
      appid: "wx55523e2b39daa330",
      scope: "snsapi_login",
      redirect_uri: encodeURIComponent('https://www.jtggls.com/login'),
      //redirect_uri: encodeURIComponent('http://qwhqpp.natappfree.cc/login'),


      searchVisible:false,
      search_index:1,
      search_name:'商品',
      searchInput:'',


      clickspfl:true,
    };
  },
  methods: {
    changePropver(index,value){
      this.searchVisible = false;
      this.search_index = index
      this.search_name = value
    },

    spanClick(value){
      this.searchInput = value
      this.searchClick()
    },

    searchClick(){
      if(this.checkLogin(true)){
        this.$store.dispatch('setSearchIndex',this.search_index)
        this.$store.dispatch('setSearchKey',this.searchInput)
        
        if(this.search_index == 1){ this.$router.push({name:'products',query:{id:'',rid:this.rid}}) }
        if(this.search_index == 2){ this.$router.push({name:'graphicClass'}) }
        if(this.search_index == 3){ this.$router.push({name: 'gallery-template', query: { type: 'list' }}) }
      }
      
    },




    // 登录
    login() {
      if(this.checkLogin()){
        if(!this.isLogin){  this.topLogin = !this.topLogin;  }
        else{ this.$router.push({name:'my'}) }
      }
    },

    // 测试方法
    logintest(){
      this.isLogin = !this.isLogin
      this.$store.dispatch('setCheckLogin',this.isLogin)

    },

    hidelogin() {
      this.topLogin = !this.topLogin;
    },



    // 获取门店和取货点
    async getStoreList() {
      let stor = await Api.getStoreList({});
      console.log(stor)
      stor.list.forEach((item) => {
        if (item.name === "近图总店") {
          this.rid = item.id;
          this.ridName = item.name;
          localStorage.setItem("room_rid", this.rid);
        }
      });
      let pick = await Api.pickupPointList({});
      let list = [];
      list.push({ name: "门店", list: stor.list });
      list.push({ name: "取货点", list: pick.list });
      this.roomList = list;
      this.getList();
    },
    // 打开关闭选择门店
    checkRoom() {
      if(this.checkLogin(true)){
        this.ischeckRoom = !this.ischeckRoom;
      }
    },
    //选择门店
    selectRoom(info) {
      if(this.checkLogin()){
        this.rid = info.id;
        this.ridName = info.name;
        localStorage.setItem("room_rid", this.rid);
        this.ischeckRoom = !this.ischeckRoom;
      }
    },

    // 获取分类
    async getList() {
      let res = await Api.GetList({ rid: this.rid });
      this.treeList = res.treeList;
      let list = [];
      this.treeList.forEach((item) => {
        item.children.forEach((info) => {
          list.push({ pid: item.id, ...info });
        });
      });
      this.leftList = list;
      this.$store.dispatch('setIndexLeftList',list)
      console.log("存")
      setTimeout(() => {
        this.isLoading = false
      },2000)
    },

    // 购物车
    gotoShop(){
      if(this.checkLogin()){
        this.shopMoney = 0
        let list = tools.getCartList()
        this.shopList = list
        if(list != null){
          this.shopList.forEach(item => {
            this.shopMoney = Number(this.shopMoney) + Number(item.totalPrice)
          })
        }
      }
    },

    // 购物车删除商品
    delGood(index){
      this.shopList.splice(index,1)
      localStorage.setItem('goodsCartList', JSON.stringify(this.shopList))
      this.gotoShop()
    },

    /** 进入购物车 */
    gotoCart(){
      if(this.checkLogin(true)){
        this.$router.push({ name: "cart"});
      }
      
    },

    // 进入商品分类
    goProducts(id) {
      if(this.checkLogin(true)){
        this.searchInput = ''
        this.$store.dispatch('setSearchKey',this.searchInput)
        this.clickspfl = false
        if(this.checkLogin()){
          this.$router.push({ name: "products", query: { id: id, rid: this.rid } });
        }
      }
    },

    goIndex(){
      this.$router.push({ name: "index" });
    },

    // 订单跟踪
    orderTracking(){  
      if(this.checkLogin(true)){
        this.$router.push({name:'my'})
      }
     },


    /** 关于 */
    about(){ this.$router.push({ name: "about"}); },
    /** 隐私政策 */
    getPrivacy(){ this.$router.push({ name: "privacy"}); },
    /** 常见问题 */
    getQuestion(){ this.$router.push({ name: "question"}); },

    /** 我的收藏 */
    myCollect(){ 
      if(this.checkLogin(true)){
        this.$router.push({ name: "myCollect"});
      }
      
    },

    /** 需求发布 */
    demand(){ 
      if(this.checkLogin(true)){
        this.$router.push({name:'demand'})
      }
      
    },
    goMyCoupon(){
      if(this.checkLogin(true)){
        this.$router.push({name:'coupon'})
      }  
    },
    
    
    tixing(){
      ElMessage({ message:'该功能正在开发中，如需体验完整版，请扫描网页最下方二维码进入小程序体验！',type:'warning', showClose:true });
    },

    // 验证登录及验证浏览器(pc或其他) 若skipVerify值为true，则需要验证登录，若为undefined 则跳过登录验证
    checkLogin(skipVerify){
      if(isWindow()){
        if(skipVerify == undefined){return true }
        else{
          if(util.isLogin()){ return true }
          else{ ElMessage({ message:'请先登录！',type:'error', showClose:true }); return false }
        }
      }else{ goApplet() }
    },
    getApplet(){ goApplet() },
  },
};
</script>

<style scoped>
.cxShop {
  display: inline-block;
  margin: 0 5px;
  width: 311px;
}
.flashDeals {
  display: inline-block;
  margin: 0 5px;
  width: 445px;
  margin-bottom: 20px;
}
.title-text {
  text-align: left;
}
.room-body {
  position: absolute;
  top: 25px;
  left: -80px;
  width: 600px;
  background: #ffffff;
  box-shadow: 0 2px 2px 1px #989898;
  border-radius: 5px;
  z-index: 10;
  padding: 10px;
  text-align: center;
}
.show-login {
  background-color: #4b4b4b70;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hide-login {
  width: 0;
  height: 0;
}
.login-body {
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
}

#app {
  height: 100%;
}
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.prop-left-change{
  width:15%;border-right:solid 2px #cdcdcd;cursor: pointer;
}
.new-search-btn{
  width: 20%;
  padding: 0 20px;
  background-color: #007dc9;
  color: #ffffff;
  border-radius: 4px;
  line-height: 1;
  padding: 10px 20px;
}

.sea-span-body{
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: left;
  padding-left: 20px;
}

.search-bottom-text {
  margin-right: 10px;
  cursor:pointer;
  font-size: 12px;
}
.sea-text-red{
  color:red;
}

.xq-head-hot{position: absolute;
  top: -4px;
  right: -30px;
  background: red;
  padding: 0 5px;
  border-radius: 40px;
  font-size: 11px;
  color: #fff;
  }

  .search-img-icon{
    width:20px;
    height:20px;
    display:inline-block
  }

  .isMobile-width{
    width:20%;
  }
  .isMobile-padding{
    padding:10px 20px;
  }

  .xq-icon{
    position: absolute;
    top: 0;
    right: 10px;
    background: red;
    color: #fff;
    padding: 2px 8px;
    font-size: 8px;
    border-radius: 50px;
  }

  .to-margin-top{
    margin-top: 12px;
  }
  .hy-name{
    position: relative;
    left: -63px;
    top: 1px;
    color: #3b3b3b;
    font-weight: bold;
    font-size: 12px;
  }
</style>
