<!--
  文件上传到阿里云OSS
-->
<template>
  <div style="display: flex">
    <div>
      <el-upload
        list-type="picture-card"
        :show-upload-list="false"
        :before-remove="deleteFile"
        :before-upload="beforeUpload"
        :http-request=" (e) => { uploadCustomRequest(e, 'preview'); }  "
      >
        <div>
          <el-icon :size="20"><Plus /></el-icon>
          <div class="ant-upload-text">点击上传</div>
        </div>
      </el-upload>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { GetSign } from "@/commonApi/index";
export default {
  name: "custom-file",
  props: {
    img_url: [String, Array],
    type: {
      type: String,
      default: "multiple",
    },
  },

  mounted() {},
  watch: {
    img_url: {
      handler: function (e) {
        this.file_url = e && e.length ? e.split(",") : [];
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      file_url: [],
      true_file_url:[],
    };
  },
  methods: {
    deleteFile(e) {
      this.file_url = []
      let url = []
      this.true_file_url.forEach(item => {
        if(item.id != e.uid){
          url.push({id:item.id,img_url:item.img_url})
          this.file_url.push(item.img_url)
        }
      })
      this.true_file_url = url
      this.$emit("changeCustomFile", this.file_url.join(","));
    },
    beforeUpload(file) {
    },
    /**
     * 生成oss文件key
     */
    osskey(len) {
      len = len || 32;
      var chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
      var maxPos = chars.length;
      var pwd = "";
      for (var i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
    async uploadCustomRequest(e, type) {
      this.uploadFlag = type;
      
        // 获取 oss 签名
        const sign = await GetSign();
        const { file } = e;
        let fileUrl = file["name"];
        let t = fileUrl.lastIndexOf(".");
        let fileType = fileUrl.substr(t);
        let fileName = this.osskey(15);
        let key = fileName + fileType;
        const { accessid, dir, expire, host, policy, signature } = sign;
        let form = new FormData();
        form.append("key", `${dir}${key}`);
        form.append("OSSAccessKeyId", accessid);
        form.append("policy", policy);
        form.append("Signature", signature);
        form.append("file", file);
        form.append("success_action_status", "200");
        console.log("form:",form)

        const uploadRes = await axios.post(host, form, {
          "Content-Type": " multipart/form-data",
        });
        if (uploadRes.status == 200) {
          if (this.type === "multiple") {
            this.file_url.push(host + "/" + dir + key);
            this.true_file_url.push({id:file.uid,img_url:host + "/" + dir + key})

            this.$emit("changeCustomFile", this.file_url.join(','));
          } else if (this.type === "single") {
            this.file_url = [host + "/" + dir + key];
            this.$emit("changeCustomFile", host + "/" + dir + key);
          }
        }
      
    },
  },
};
</script>

<style scoped>
.up-img-box {
  position: relative;
  cursor: pointer;
  width: 120px;
}
.up-img-item {
  width: 110px;
  height: 103px;
  border: 1px dashed #d9d9d9;
}
.up-img-remove {
  position: absolute;
  right: 0px;
  top: -10px;
  color: #ff0000 !important;
  font-size: 24px;
}
</style>
