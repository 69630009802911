
export default {
    /**
     * 添加商品到购物车
     * @author
     * */
    addGoodCart(good) {
        let cartList = JSON.parse(localStorage.getItem('goodsCartList')) ? JSON.parse(localStorage.getItem('goodsCartList')) : [];
        this.restructureCart(good, cartList)
    },
    /**
	* 重构购物车数据
	* @author
	* */
	restructureCart(good, cartList) {
		good['specsIndex'] = good['sku_json'].findIndex(item=>item['selected'] == true);
		console.log("good_id:" , good.id , good.specsIndex , good.design.designType)
		if (cartList.length) {
			let flag = true;
			cartList.forEach((item, index)=>{
				console.log("list_id:" , item.id ,item.specsIndex , item.design.designType)
				if (item.id == good.id && item.specsIndex == good.specsIndex && item.design.designType == good.design.designType) {
					item['amount'] = item['amount'] + good['amount'];
					flag = false;
				}
			})
			if (flag) {
				cartList.push(good)
			}
		}else{
			cartList.push(good)
		}
		localStorage.setItem('goodsCartList', JSON.stringify(cartList))
	},
    /**
	 * 返回购物车数据
	 * @author
	 * */
	 getCartList() {
        return JSON.parse(localStorage.getItem('goodsCartList'))
    },
    /**
	  * 清空购物车数据
	  * @author
	  * */
	  clearCart() {
        localStorage.setItem('goodsCartList', null)
    },

	/**
	  * 删除购物车某个数据
	  * */
	clearCartById(id) {
		let newList = []
        let goodlist = JSON.parse(localStorage.getItem('goodsCartList'))
		goodlist.forEach(item => {
			if(item.id != id){
				newList.push(item)
			}
		})
		localStorage.setItem('goodsCartList',JSON.stringify(newList) )
    },





	/** 收藏商品 */
	goodCollection(id,good){
		let info = {title:'',type:false,};
		let goodList = JSON.parse(localStorage.getItem('collectGoodList')) ? JSON.parse(localStorage.getItem('collectGoodList')) : [];
		let flag = true;
		if(goodList.length > 0){
			try{
				goodList.forEach( (item) => {
					if(item.id === id){ throw new Error('End Loop') } // 存在相同id，执行报错，终止循环
				})
				goodList.push(good)
				info = {title:'收藏成功',type:true};
			}catch(e){
				info = {title:'已在我的收藏中',type:false};
			}
		}else{
			goodList.push(good)
			info = {title:'收藏成功',type:true};
		}


		localStorage.setItem('collectGoodList',JSON.stringify(goodList))
		console.log(JSON.parse(localStorage.getItem('collectGoodList')))
		return info
	},
	/** 收藏图文 */
	classCollection(id,good){
		let info = {title:'',type:false,};
		let goodList = JSON.parse(localStorage.getItem('collectClassList')) ? JSON.parse(localStorage.getItem('collectClassList')) : [];
		let flag = true;
		if(goodList.length > 0){
			try{
				goodList.forEach( (item) => {
					if(item.id === id){ throw new Error('End Loop') } // 存在相同id，执行报错，终止循环
				})
				goodList.push(good)
				info = {title:'收藏成功',type:true};
			}catch(e){
				info = {title:'已在我的收藏中',type:false};
			}
		}else{
			goodList.push(good)
			info = {title:'收藏成功',type:true};
		}


		localStorage.setItem('collectClassList',JSON.stringify(goodList))
		console.log(JSON.parse(localStorage.getItem('collectClassList')))
		return info
	},



}
