
import Vuex from 'vuex';


export default new Vuex.Store({
  state: {
    menuList: [],
    dynamicMenuList: [],
    barMenuList: [],
    requestLoading: false,

    searchIndex:'',
    searchKey:'',

    checkLogin:false,

    indexLetfList:[],

  },
  mutations: {
    SET_MENU(state, data) {
      state.menuList = data;
    },
    SET_DYNAMIC_MENU(state, data) {
      state.dynamicMenuList = data;
    },
    SET_BAR_MENU(state, data) {
      state.barMenuList = data;
    },
    SET_REQUEST_LOADING(state, data) {
      state.requestLoading = data;
    },
    SET_SEARCH_INDEX(state,value){
      state.searchIndex = value
    },
    SET_SEARCH_KEY(state,value){
      state.searchKey = value
    },
    SET_CHECK_LOGIN(state,value){
      state.checkLogin = value
    },
    SET_INDEX_LEFT_ILST(state,value){
      state.indexLetfList = value
    }
  },
  actions: {
    setSearchIndex(context,value){
      context.commit('SET_SEARCH_INDEX',value)
    },
    setSearchKey(context,value){
      context.commit('SET_SEARCH_KEY',value)
    },
    setCheckLogin(context,value){
      context.commit('SET_CHECK_LOGIN',value)
    },
    setIndexLeftList(context,value){
      context.commit('SET_INDEX_LEFT_ILST',value)
    }
  },
  modules: {},
});
