<template>
    <Header v-show="!(path === '/login')"></Header>

    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />


    <Footer v-show="!(path === '/login') && !(path === '/my')"></Footer>
</template>
<script>
  import Header from '@/components/header'
  import Footer from '@/components/footer'

  import  '@/assets/static/js/jquery.min.js';
  import  '@/assets/static/js/bootstrap.bundle.min.js';
  import  '@/assets/static/js/meanmenu.min.js';
  import  '@/assets/static/js/wow.min.js';
  import  '@/assets/static/js/range-slider.min.js';
  import  '@/assets/static/js/form-validator.min.js';
  import  '@/assets/static/js/contact-form-script.js';
  import  '@/assets/static/js/ajaxchimp.min.js';
  import  '@/assets/static/js/owl.carousel.min.js';
  import  '@/assets/static/js/custom.js';
  
  import "@/assets/static/css/bootstrap.min.css" 
  import "@/assets/static/css/owl.theme.default.min.css" 
  import "@/assets/static/css/owl.carousel.min.css" 
  import "@/assets/static/css/remixicon.css" 
  import "@/assets/static/css/meanmenu.min.css" 
  import "@/assets/static/css/animate.min.css" 
  import "@/assets/static/css/style.css" 
  import "@/assets/static/css/responsive.css" 

  export default{
    data(){
      return {
        path:'',
      }
    },
    components:{
      Header,
      Footer
    },
    mounted(){
      if(!window.login_code ){
        let code = localStorage.getItem('login_code')
        if(code != null){
          window.login_code = code.slice(1,code.length-1)
        }
      }
      
      this.path = this.$route.path
    },
    methods:{
      _isMobile(){
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|Windows)/i)
        return flag
      }
    },
    watch:{
      $route(to,form){
        this.path = to.path
      }
    }


  }

  

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
