
function _isMobile(){
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|Windows)/i)
    return flag
}
export function isWindow(){
    let list = _isMobile()
    console.log("判断当前打开网页机型：", list[0])
    if(list[0] === 'Windows'){
        return true
    }
    return false
}

export async function goApplet(){
    let url = 'https://o.uiuin.cn/ecbSdl'  //微客外链
    window.location.href = url
}