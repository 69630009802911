<template>
  <div>

        <div class="footer-area pt-54 pb-30" style="background: #333333;color:#868686">
            <div class="container" v-if="isMobile">
                <div class="row">

                    <div class="col-lg-2 col-sm-6">
                        <div class="single-footer-widget">
                           <img src="@/assets/static/icons/fot.png" alt="">
                        </div>
                    </div>

                    <div class="col-lg-2 col-sm-6">
                        <div class="single-footer-widget">
                            <div style="margin-bottom: 25px;"> <span class="foot-title-span">关于我们</span> </div>
                           
                            <ul class="import-link">
                                <li> <a class="foot-a-col" @click="about">公司简介</a></li>
                                <li> <a class="foot-a-col" href="https://work.weixin.qq.com/kfid/kfc7f17646550b3ddf0">联系我们</a> </li>
                                <li> <a class="foot-a-col" @click="goCooperation">加入我们</a> </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-2 col-sm-6">
                        <div class="single-footer-widget">
                            <div style="margin-bottom: 25px;"> <span class="foot-title-span">下单指南</span> </div>
                            <ul class="import-link">
                                <li> <a class="foot-a-col" @click="pictureAndTextLectureHall">图文讲堂</a> </li>
                                <li> <a class="foot-a-col" @click="getQuestion">下单流程</a> </li>
                                <li> <a class="foot-a-col" @click="goMyCoupon">活动领取与使用</a> </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-2 col-sm-6">
                        <div class="single-footer-widget">
                            <div style="margin-bottom: 25px;"> <span class="foot-title-span">帮助中心</span> </div>
                            <ul class="import-link">
                                <li> <a class="foot-a-col" @click="getQuestion">帮助中心</a> </li>
                                <li> <a class="foot-a-col" @click="LibraryTemplate">图库模板下载</a> </li>
                                <li> <a class="foot-a-col" @click="getQuestion">退款说明</a> </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="single-footer-widget code-img-body">
                            <ul class="app-btn" style="margin-bottom: 0;">
                                <li class="foot-li-bott col-lg-3" style="margin-right:0;">
                                    <img src="@/assets/static/codeImg/qrCode.png" />
                                    <div class="code-img-name">近图小程序</div>
                                </li>
                                <li class="foot-li-bott col-lg-3" style="margin-right:0;">
                                    <img src="@/assets/static/codeImg/gongzhonghao.jpg" />
                                    <div class="code-img-name">近图公众号</div>
                                </li>
                                <li class="foot-li-bott col-lg-3" style="margin-right:0;">
                                    <img src="@/assets/static/codeImg/douyinhao.png" />
                                    <div class="code-img-name">近图抖音号</div>
                                </li>
                                <li class="foot-li-bott col-lg-3" style="margin-right:0;">
                                    <img src="@/assets/static/codeImg/shipinghao.png" />
                                    <div class="code-img-name">近图视频号</div>
                                </li>
                                
                            </ul>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div style="border-top:solid 1px #4c4c4c;padding-top:20px">
                ©2023 甘肃近图企服科技有限公司  ICP备案号：陇ICP备2022001956号-2
            </div>

        </div>
        <!-- 回到顶部按钮 -->
        <div class="go-top" @click="toTop" style="height: 55px;">
            <i class="ri-arrow-up-s-fill"></i><i class="ri-arrow-up-s-fill"></i>
            <div style="padding-top:20px">顶部</div>
        </div>  


  </div>
</template>

<script>
import {isWindow,goApplet} from '@/views/index/goApplet'
import * as util from '@/utils/loginUtil'
import { ElMessage} from 'element-plus'

export default {
    data(){
        return{
            isMobile:false,
        }
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll, true);
        this.isMobile = isWindow()
    },
    methods:{
        handleScroll() {
            let scrolltop =document.documentElement.scrolltop || document.body.scrollTop;
            scrolltop > 30 ? (this.gotop = true) : (this.gotop = false);
        },
        toTop() {
            let top = document.documentElement.scrollTop || document.body.scrollTop;
            const timeTop = setInterval(() => {
                document.body.scrollTop = document.documentElement.scrollTop = top -= 200;
                if (top <= 0) {
                    clearInterval(timeTop);
                }
            }, 10);
        },
        /** 活动领取与使用 */
        goMyCoupon(){ if(this.checkLogin(true)){ this.$router.push({name:'coupon'}) } },
        /** 合作商入口 */
        goCooperation(){ if(this.checkLogin(true)){ this.$router.push({name:'cooperation'}) } },
        /** 图文讲堂 */
        pictureAndTextLectureHall(){ if(this.checkLogin(true)){ this.$router.push({name:'graphicClass'}) } },
        /** 图库模板 */
        LibraryTemplate(){ if(this.checkLogin(true)){ this.$router.push({ name: 'gallery-template', query: { type: 'list' } }) } },
        /** 关于 */
        about(){ if(this.checkLogin()){ this.$router.push({ name: "about"}); } },
        /** 隐私政策 */
        getPrivacy(){ if(this.checkLogin()){ this.$router.push({ name: "privacy"}); } },
        /** 常见问题 */
        getQuestion(){ if(this.checkLogin()){ this.$router.push({ name: "question"}); } },

        // 验证登录及验证浏览器(pc或其他) 若skipVerify值为true，则需要验证登录，若为undefined 则跳过登录验证
        checkLogin(skipVerify){
            if(isWindow()){
                if(skipVerify == undefined){return true }
                else{
                    if(util.isLogin()){ return true }
                    else{ ElMessage({ message:'请先登录！',type:'error', showClose:true }); return false }
                }
            }else{ goApplet() }
        }
    }
}
</script>

<style>
    .foot-a-col{
        color:#868686;
    }
    .foot-a-col:hover{
        color:#ffffff;
    }

    .foot-h-title{
        color:#ffffff;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: solid 2px #ffffff;
    }
    
    .foot-title-span{
        color:#ffffff;
        padding: 15px 20px;
        border-bottom: solid 2px #fff;
        font-size: 22px;
    }
    .code-img-name{
        margin-top: 5px;
        font-weight: bold;
        color: #000;
    }

    .foot-li-bott{
        margin-bottom:0;
        margin-right:0;
    }
    .code-img-body{
        background: #ffffff;
        padding:10px;
        box-shadow: inset 1px 1px 4px 4px #b2b2b2;
    }

    .new-col-lg-2{
        flex: 0 0 auto;
        width:15%;
    }

    .new-col-lg-3{
        flex: 0 0 auto;
        width:40%;
    }
</style>