// 首页
import request from '@/utils/request';

// 获取门店
export function getStoreList(data) {
    return request({
        url: '/xcx/nologin/room/info/getRoomList',
        method: 'GET',
        params: data
    });
}
// 获取取货点
export function pickupPointList(data) {
    return request({
        url: '/xcx/nologin/pickupPoint/info/getList',
        method: 'GET',
        params: data
    });
}

// 首页获取分类 （四个大类，无数个小类）
export function GetList(data) {
    return request({
        url: '/xcx/nologin/good/info/getList',
        method: 'GET',
        params: data
    });
}

// 微信登录
export function Login(data) {
    return request({
        url: '/xcx/login/PCLogin',
        method: 'GET',
        params: data
    });
}
// 微信登录电话授权
export function Binding(data) {
    return request({
        url: '/xcx/login/PcBinding',
        method: 'GET',
        params: data
    });
}


// 获取轮播图
export function GetAdvList(data) {
    return request({
        url: '/xcx/nologin/pay/payStore/getTopAdvList',
        method: 'GET',
        params: data
    });
}


// 获取轮播图
export function getIndex(data) {
    return request({
        url: '/xcx/nologin/good/info/getIndex',
        method: 'GET',
        params: data
    });
}

// 首页展示图文
export function searchGraphic(data) {
    return request({ url: '/store/searchGraphic', method: 'GET', params: data });
}
