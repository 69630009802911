export function setloginCode(login_code){
    localStorage.setItem('login_code', JSON.stringify(login_code))
}
export function getloginCode(){
    return localStorage.getItem('login_code')
}

export function setCustom(custom){
    localStorage.setItem('custom', JSON.stringify(custom))
}
export function getCustom(){
    return localStorage.getItem('custom')
}

export function getCodeUrl(){
    return 'http://jnvr8s.natappfree.cc/login'
}


export function isLogin(){
    let isLogin = localStorage.getItem('is_login') ==='true'
    if(isLogin){
        return true
    }else{
        return false
    }
}